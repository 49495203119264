.App {
  text-align: center;
}

hr.rounded {
  border-top: 8px solid #bbb;
  border-radius: 5px;
}

.center {
  display: grid;
  justify-content: center;
  align-content: center;

  gap: 4px;
  grid-auto-flow: column;
}
.parent {
  display: grid;
  text-align: center;
  margin: auto;
  padding: 10px;
  gap: 10px;
  margin: auto;
  outline: 5px solid #121111;
  width: 900px;
}

/*.headders {
  display: grid;
  width: 800px;
  margin: auto;
  padding: 10px;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  color: white;
}*/

.pallete {
  padding: 3px;
  gap: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.pallete-box {
  height: 25px;
  width: 30px;
}

.answers {
  display: grid;
  text-align: center;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  width: 800px;
  margin: auto;
  padding: 10px;
  gap: 10px;
}


.attributes {
  padding: 6px;
  color: white;
  font-size: 1.4vw;
  white-space: nowrap;
}
@media (max-width: 1000px) {
  .attributes {
    font-size: 3vw;
  }
}
.attribute-container{
  padding-top: 20px;
  padding-bottom: 10px;
}

.shadow-box {
  display: flex;
  box-shadow: inset 0 0 15px #121111;
  border: 1px solid #121111;
  background-color: #bdb5b5;
  min-height: 100px;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.spinner {
  transform: rotateX(180deg);
  animation: flip 1s ease forwards;
  backface-visibility: hidden;
}

@keyframes flip {
  from {
    transform: perspective(100px) rotateX(180deg);
  }
  to {
    transform: perspective(100px) rotateX(360deg);
  }
}


.spinner:nth-child(3) {
  animation-delay: 150ms;
}
.spinner:nth-child(4) {
  animation-delay: 300ms;
}
.spinner:nth-child(5) {
  animation-delay: 450ms;
}
.spinner:nth-child(6) {
  animation-delay: 600ms;
}

.scrollable {
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
}
.close{
font-size: 26px;
font-weight: bold;
}
.clicked-emoji {
  padding: 25px;
}
.no-emoji {
  padding-top: 75px;
  padding-bottom: 55px;
  padding-left: 35px;
  padding-right: 35px;
  color: white;
  font-size: 1.2vw;
  white-space: nowrap;
}
@media (max-width: 1000px) {
  .no-emoji {
    font-size: 6vw;
  }
}
.button-center {
  padding-top: 40px;
}

.EmojiPickerReact {
  zoom: 115%;
}
@media (max-width: 1000px) {
  .EmojiPickerReact {
    max-width: 95%;
    zoom: 180%;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;

}

.modal_content {
  background-color: lightgreen;
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
  justify-content: center;
  width: 900px;
  max-height: 600px;
  overflow-x:hidden;
  overflow-y:auto;
}
@media (max-width: 1000px) {
  .modal_content {
    width: 90%;
    max-height: 70%;

  }
}

.results{
  max-width: 170px;
  font-size: 26px;
  margin: auto;
  padding-top: 25px;
  padding-bottom: 10px;
  overflow-y: auto;
  max-height: 300px;
  gap: 10px
}
@media (max-width: 1000px) {
  .results {
    font-size: 54px;
    max-width: 330px;
    max-height: 800px;
  }
}

/* CSS */
.button-20 {
  appearance: button;
  background-color: #4d4ae8;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  border: 1px solid #4d4ae8;
  border-radius: 1rem;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: 0.5rem 1rem;
  text-align: center;
  text-transform: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  height: 70px;
  width: 150px;
}
@media (max-width: 1000px) {
  .button-20 {
    height: 100px;
    width: 250px;

  }
}

.congrats{
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.timer{
  font-size: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
}


.button-20:focus:not(:focus-visible),
.button-20:focus {
  outline: 0;
}

.button-20:hover {
  background-color: #3733e5;
  border-color: #3733e5;
}

.button-20:focus {
  background-color: #413fc5;
  border-color: #3e3bba;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px, rgba(104, 101, 235, 0.5) 0 0 0 0.2rem;
}

.button-20:active {
  background-color: #3e3bba;
  background-image: none;
  border-color: #3a38ae;
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset;
}

.button-20:active:focus {
  box-shadow: rgba(46, 54, 80, 0.125) 0 3px 5px inset,
    rgba(104, 101, 235, 0.5) 0 0 0 0.2rem;
}

.button-20:disabled {
  background-image: none;
  box-shadow: none;
  opacity: 0.65;
  pointer-events: none;
}

/* CSS */
.button-pad {
  padding-top: 30px;
  padding-bottom: 10px;
}

.button-28 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}
@media (max-width: 1000px) {
  .button-28 {
   font-size: 36px;
  }
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}
